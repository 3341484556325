import React, { Children, FC, useEffect, useMemo, useRef, useState } from 'react';
import objectHash from 'object-hash';
import stringify from 'safe-stable-stringify';

import { AccordionContainerProps } from './models';

const AccordionContainer: FC<AccordionContainerProps> = ({
  children,
  showIndex,
  firstChildOpened,
}) => {
  const [openId, setOpenId] = useState<string | null>(null);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const accordionIDs: string[] = useMemo(
    () => Children.toArray(children).map((child) => objectHash(stringify(child))),
    []
  );

  const handleAddTimer = (timer) => {
    timerRef.current = timer;
  };

  const handleClearTimer = () => {
    if (!timerRef.current) return;
    clearTimeout(timerRef.current);
  };

  useEffect(() => {
    if (firstChildOpened) setOpenId(accordionIDs[0]);

    return () => {
      if (!timerRef.current) return;
      clearTimeout(timerRef.current);
    };
  }, [accordionIDs]);

  const handleToggle = (id) => {
    setOpenId(openId === id ? null : id);
  };

  return (
    <>
      {!accordionIDs.length ? null : (
        <div className="accordion-container">
          {React.Children.map(children, (child, i) => (
            <child.type
              {...child.props}
              key={accordionIDs[i]}
              onToggle={() => handleToggle(accordionIDs[i])}
              expanded={openId === accordionIDs[i]}
              showIndex={showIndex}
              index={i}
              addTimer={handleAddTimer}
              clearTimer={handleClearTimer}
              accordionId={accordionIDs[i]}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default AccordionContainer;
