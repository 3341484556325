import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { debounce } from 'lodash';

import Plus from '../../icons/plus.svg';
import Minus from '../../icons/uncheck.svg';
import { AccordionProps } from './models';

import './Accordion.scss';

const Accordion: FC<AccordionProps> = ({
  children,
  accordionId,
  expanded,
  onToggle,
  title,
  titleFont,
  btnTextCollapse,
  btnTextExpand,
  showIndex,
  index,
  addTimer,
  clearTimer,
  scrollTo = true,
}) => {
  const accordionRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  if (!onToggle) return null;

  const handleClick = () => {
    if (accordionId) onToggle(accordionId);
    if (clearTimer) clearTimer();

    if (addTimer && scrollTo) {
      addTimer(
        setTimeout(() => {
          const offset = accordionRef.current?.offsetTop;
          window.scrollTo(0, (offset || 0) - 50);
        }, 300)
      );
    }
  };

  const handleResize = debounce(() => {
    setWidth(window.innerWidth);
  }, 500);

  useEffect(() => {
    setWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const bodyElement = useMemo(
    () => (
      <div
        ref={bodyRef}
        className="accordion__body"
        style={{
          height: expanded ? `${contentRef.current?.scrollHeight}px` : '0',
          visibility: expanded ? 'visible' : 'hidden',
        }}
      >
        <div
          className="accordion__content"
          id={`accordion-content-${accordionId}`}
          ref={contentRef}
          role="region"
          aria-labelledby={accordionId}
        >
          {children}
        </div>
      </div>
    ),
    [expanded, width, children]
  );

  return (
    <div className="accordion" ref={accordionRef}>
      <button
        className="accordion__btn"
        type="button"
        onClick={handleClick}
        aria-expanded={expanded}
        aria-controls={`accordion-content-${accordionId}`}
        id={accordionId}
      >
        <h2 className="accordion__heading">
          {showIndex ? (
            <span className="accordion__index">
              {(new Array(2).join('0') + ((index || 0) + 1)).slice(-2)}
            </span>
          ) : null}
          <span className={`accordion__btn-text ${titleFont}`}>{title}</span>
        </h2>
        <span className="link link--pink">
          {expanded ? btnTextCollapse : btnTextExpand}
          {expanded ? (
            <Minus
              className="accordion__icon"
              data-testid={`collapse-btn-${title}`}
              aria-hidden="true"
            />
          ) : (
            <Plus
              className="accordion__icon"
              data-testid={`expand-btn-${title}`}
              aria-hidden="true"
            />
          )}
        </span>
      </button>
      {bodyElement}
    </div>
  );
};

export default Accordion;
