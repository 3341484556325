import React, { FC } from 'react';

import { Col, Container, Row } from 'layout';
import Accordion from 'common/AccordionContainer/Accordion/Accordion';
import AccordionContainer from 'common/AccordionContainer/AccordionContainer';
import Image from 'common/Image';
import LinkItem from 'common/LinkItem';

import { PlainAccordionProps } from './models';

import './PlainAccordion.scss';

const PlainAccordion: FC<PlainAccordionProps> = ({ module }) => (
  <section className="plain-accordion section rte-white">
    <Container>
      <Row>
        <Col className="col--no-gutters">
          <AccordionContainer firstChildOpened={module.firstItemOpened}>
            {module.items?.map((item, index) => (
              <Accordion
                key={`${item.title}-${index}`}
                title={item.title}
                titleFont="h5"
                btnTextExpand={module.expandButtonText}
                btnTextCollapse={module.collapseButtonText}
              >
                <div className="plain-accordion__content">
                  <div
                    className="accordion__rte rte"
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />
                  <LinkItem
                    link={item.ctaLink}
                    linkClass="accordion__link link link--pink"
                    hasIcon
                    isLink
                    showTitle
                  />
                </div>
                {item.image ? (
                  <Image
                    imageData={item.image}
                    alt={item.image.altText}
                    className="accordion__media"
                  />
                ) : null}
              </Accordion>
            ))}
          </AccordionContainer>
        </Col>
      </Row>
    </Container>
  </section>
);

export default PlainAccordion;
